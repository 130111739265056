import styled from "@emotion/styled";

import { Color, TextStyle, Sizing } from "./Text.props";

const fontSizing: Record<Sizing, string> = {
  small: `
        font-size: 18px;
    `,
  medium: `
        font-size: 24px;
    `,
  large: `
        font-size: 30px;
    `,
};

const colors: Record<Color, string> = {
  black: `
        color: midnightblue;
    `,
  white: `
        color: white;
    `,
  red: `
        color: tomato;
    `,
};

export const Text = styled.div<TextStyle>`
  ${({ sizing }) => fontSizing[sizing]}
  ${({ color }) => colors[color]}
  font-family: "Montserrat", sans-serif;
  display: flex;
  align-items: center;
`;
