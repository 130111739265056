import styled from "@emotion/styled";

export const Container = styled.div`
  display: grid;
  justify-items: center;
  gap: 16px;
`;

export const QrCode = styled.img`
  width: 100px;
`;
