import React from "react";
import { CarouselProps } from "./Carousel.props";
import { Container } from "./Carousel.style";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination } from "swiper/modules";

import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "./Carousel.css";

const CarouselComponent = (props: CarouselProps): JSX.Element => {
  const { slides, style } = props;

  return (
    <Container style={style}>
      <Swiper
        navigation={true}
        pagination={true}
        modules={[Navigation, Pagination]}
        spaceBetween="32px"
      >
        {slides.map((slideMapItem, slideMapItemIndex) => (
          <SwiperSlide key={slideMapItemIndex}>{slideMapItem}</SwiperSlide>
        ))}
      </Swiper>
    </Container>
  );
};

export default React.memo(CarouselComponent);
