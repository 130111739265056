import styled from "@emotion/styled";

export const Container = styled.div`
  display: grid;
  gap: 32px;

  @media screen and (min-width: 992px) {
    & {
      grid-template-columns: 1fr 1fr;
      justify-items: stretch;
    }
  }
`;

export const MessageContainer = styled.div`
  align-self: center;
  display: grid;
  justify-items: center;
  gap: 16px;
`;

export const LinkContainer = styled.div`
  display: flex;
  justify-content: center;
  gap: 8px;
`;

export const Anchor = styled.a``;

export const FormContainer = styled.div`
  display: grid;
  gap: 16px;
`;
