import React from "react";
import { AboutProps } from "./About.props";
import {
  AboutFootContainer,
  AboutHeadContainer,
  Container,
  Image,
  ImageContainer,
  ItemContainer,
  ItemFootContainer,
  ItemHeadContainer,
  ListContainer,
  MessageContainer,
} from "./About.style";
import Text from "../../component/Text";

const AboutView = (props: AboutProps): JSX.Element => {
  const { style } = props;

  return (
    <Container id="about" style={style}>
      <AboutHeadContainer>
        <ImageContainer>
          <Image src="/assets/photo.jpg" />
        </ImageContainer>
        <MessageContainer>
          <Text sizing="small" style={{ lineHeight: 2, letterSpacing: "2px" }}>
            I was born in Korea and can speak Korean, Japanese, and English. I
            am dedicated to crafting code that not only works but also meets
            high standards. I am eager to acquire new knowledge and passionate
            about taking on new challenges.
          </Text>
        </MessageContainer>
      </AboutHeadContainer>
      <AboutFootContainer>
        <ListContainer>
          <ItemContainer>
            <ItemHeadContainer>
              <Text sizing="small" bold>
                Web and Mobile App Design and Development, Post-Degree Diploma
              </Text>
              <Text sizing="small">2023 - 2024</Text>
            </ItemHeadContainer>
            <ItemFootContainer>
              <Text sizing="small">Langara College, Vancouver, Canada</Text>
            </ItemFootContainer>
          </ItemContainer>
          <ItemContainer>
            <ItemHeadContainer>
              <Text sizing="small" bold>
                Junior Java Developer
              </Text>
              <Text sizing="small">2019 - 2022</Text>
            </ItemHeadContainer>
            <ItemFootContainer>
              <Text sizing="small">Techave, Tokyo, Japan</Text>
            </ItemFootContainer>
          </ItemContainer>
          <ItemContainer>
            <ItemHeadContainer>
              <Text sizing="small" bold>
                Computer & Information Engineer, Bachelor of Engineering
              </Text>
              <Text sizing="small">2011 - 2018</Text>
            </ItemHeadContainer>
            <ItemFootContainer>
              <Text sizing="small">Chonnam University, Gwangju, Korea</Text>
            </ItemFootContainer>
          </ItemContainer>
        </ListContainer>
      </AboutFootContainer>
    </Container>
  );
};

export default React.memo(AboutView);
