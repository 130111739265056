import React from "react";
import { SkillProps } from "./Skill.props";
import { Container } from "./Skill.style";
import { FaJava, FaPython, FaReact, FaWordpress } from "react-icons/fa";
import {
  SiTypescript,
  SiJavascript,
  SiHtml5,
  SiCss3,
  SiSpring,
  SiMongodb,
  SiPostgresql,
  SiGit,
  SiFirebase,
  SiNextdotjs,
} from "react-icons/si";
import { DiNodejs } from "react-icons/di";

const SkillView = (props: SkillProps): JSX.Element => {
  const { style } = props;

  return (
    <Container id="skill" style={style}>
      <SiTypescript size={64} color="midnightblue" />
      <SiJavascript size={64} color="midnightblue" />
      <SiHtml5 size={64} color="midnightblue" />
      <SiCss3 size={64} color="midnightblue" />
      <FaJava size={64} color="midnightblue" />
      <SiSpring size={64} color="midnightblue" />
      <FaPython size={64} color="midnightblue" />
      <FaReact size={64} color="midnightblue" />
      <SiNextdotjs size={64} color="midnightblue" />
      <DiNodejs size={64} color="midnightblue" />
      <SiMongodb size={64} color="midnightblue" />
      <SiPostgresql size={64} color="midnightblue" />
      <SiFirebase size={64} color="midnightblue" />
      <SiGit size={64} color="midnightblue" />
      <SiGit size={64} color="midnightblue" />
      <FaWordpress size={64} color="midnightblue" />
    </Container>
  );
};

export default React.memo(SkillView);
