import ChatBot from "react-chatbotify";
import { MoonProps } from "./Moon.props";
import { Container } from "./Moon.style";

const MoonView = (props: MoonProps): JSX.Element => {
  const flow = {
    start: {
      path: "san_ka_punta",
      transition: { duration: 0 },
    },
    san_ka_punta: {
      message: "San ka punta?",
      path: "to_the_moon",
      transition: { duration: 0 },
    },
    to_the_moon: {
      options: ["To the moon"],
      path: "process_to_the_moon",
    },
    process_to_the_moon: {
      path: async (params: { userInput: string }) => {
        if (params.userInput === "To the moon") {
          return "road_trip";
        } else {
          return "san_ka_punta";
        }
      },
      transition: { duration: 0 },
    },
    road_trip: {
      message: "Roadtrip",
      path: "broom_broom",
      transition: { duration: 0 },
    },
    broom_broom: {
      options: ["broom broom"],
      path: "process_broon_broom",
    },
    process_broon_broom: {
      path: async (params: { userInput: string }) => {
        if (params.userInput === "broom broom") {
          return "skrrr_skrrr";
        } else {
          return "road_trip";
        }
      },
      transition: { duration: 0 },
    },
    skrrr_skrrr: {
      message: "Skrrr skrr",
      path: "zoom_zoom",
      transition: { duration: 0 },
    },
    zoom_zoom: {
      options: ["zoom zoom"],
      path: "process_zoom_zoom",
    },
    process_zoom_zoom: {
      path: async (params: { userInput: string }) => {
        if (params.userInput === "zoom zoom") {
          return "end";
        } else {
          return "skrrr_skrrr";
        }
      },
      transition: { duration: 0 },
    },
    end: { chatDisabled: true },
  };

  return (
    <Container>
      <ChatBot
        options={{
          chatHistory: { disabled: true },
          header: { title: "MC JejeMon", showAvatar: false },
          theme: {
            embedded: true,
            showFooter: false,
          },
        }}
        flow={flow}
      />
    </Container>
  );
};

export default MoonView;
