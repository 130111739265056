import styled from "@emotion/styled";

import { Sizing, Color, ButtonStyles } from "./Button.props";

const sizes: Record<Sizing, string> = {
  small: `
        padding: 4px 8px;
        font-size: 12px;
        `,
  medium: `
        padding: 6px 12px;
        font-size: 16px;
        `,
  large: `
        padding: 8px 16px;
        font-size: 20px;
    `,
};

const colors: Record<Color, string> = {
  blue: `        
        background-color: midnightblue;
        color: white;
        &:hover {
          background-color: tomato;
        }
    `,
  transparent: `  
        background-color: transparent;
        color: midnightblue;
        &:hover {
          color: tomato;
        }
    `,
};

export const Button = styled.button<ButtonStyles>`
  ${({ sizing }) => sizes[sizing]};
  ${({ color }) => colors[color]};
  ${({ block }) => block && "display: block; width: 100%;"}
  ${({ nopadding }) => nopadding && "padding: 0;"}
  border-radius: 8px;
  border: 0;
  white-space: nowrap;
  cursor: pointer;
`;

export const Children = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
`;
