import styled from "@emotion/styled";

export const Header = styled.header`
  position: fixed;
  width: 100%;
  top: 0;
  background-color: white;
  border-bottom: 1px solid lightgray;
  z-index: 10;
`;

export const Nav = styled.nav`
  padding: 16px;
  display: flex;
  justify-content: space-between;

  @media screen and (min-width: 992px) {
    & {
      padding: 32px 5%;
    }
  }
`;

export const LogoContainer = styled.div``;

export const AnchorContainer = styled.div``;

export const Anchor = styled.a`
  display: inline-grid;
  align-content: center;
  text-decoration: none;
`;

export const LinkContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 8px;
`;

export const Navigation = styled.div`
  position: fixed;
  right: 0;
  top: 0;
  background-color: white;
  width: 100%;
  height: 100%;
  max-width: 320px;
  border-left: 1px solid lightgray;
`;

export const NavigationHeadContainer = styled.div`
  padding: 16px;
  display: flex;
  justify-content: flex-end;
`;

export const NavigationFootContainer = styled.div`
  padding: 16px;
  display: flex;
  flex-direction: column;
  gap: 16px;
  align-items: flex-end;
`;

export const Main = styled.main`
  padding: 32px 16px;

  @media screen and (min-width: 992px) {
    & {
      padding: 32px 15%;
    }
  }
`;

export const Footer = styled.footer`
  padding: 16px;

  @media screen and (min-width: 992px) {
    & {
      padding: 32px 15%;
    }
  }
`;
