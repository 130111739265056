import styled from "@emotion/styled";

export const Container = styled.div`
  display: grid;
  gap: 64px;
  overflow: hidden;
`;

export const ProjectContainer = styled.div`
  min-width: 0;
  display: flex;
  gap: 32px;

  @media screen and (min-width: 992px) {
    & {
      display: grid;
    }
  }
`;

export const ImageContainer = styled.div`
  border-radius: 8px;
  border: 1px solid lightgray;
  font-size: 0;
`;

export const Image = styled.img`
  border-radius: 8px;
  height: 100%;
  object-fit: cover;
  object-position: top left;
`;

export const MessageContainer = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  gap: 16px;
`;

export const StackList = styled.ul`
  margin: 0;
  padding: 0 0 0 16px;
  display: flex;
  column-gap: 32px;
  row-gap: 16px;
  flex-wrap: wrap;
`;

export const StackItem = styled.li``;

export const LinkContainer = styled.div`
  display: flex;
  gap: 8px;
`;

export const Anchor = styled.a``;
